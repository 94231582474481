<template>
    <v-container fluid>
        <v-tabs v-model="tab">
            <v-tab @click="goBack()">Ricerca ordini di vendita</v-tab>
            <v-tab disabled>Righe dell'ordine di vendita</v-tab>
            <!-- MASCHERA DI RICERCA ORDINI DI VENDITA -->
            <v-tab-item>
                <h1 align="center">Pianificazione attività</h1>

                <v-row class="mt-4">
                    <v-col>
                        <v-text-field clearable dense label="Ordine di vendita" v-model="documentNo" hide-details outlined></v-text-field>
                        <!-- <v-autocomplete dense :items="odvs" item-value="documentNo" item-text="documentNo"
                        label="Ordine di vendita" v-model="documentNo"></v-autocomplete>  -->
                    </v-col>
                    <v-col>
                        <v-autocomplete clearable label="Cliente" hide-no-data hide-selected :items="bpartners"
                            item-text="name" item-value="c_bpartner_id" v-model="c_bpartner_id"
                            :search-input.sync="ricercaCliente" dense hide-details outlined></v-autocomplete>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <DataPicker clearable dense label="Data Ordine" v-model="dataOrdine" hide-details outlined></DataPicker>
                    </v-col>
                    <v-col>
                        <v-autocomplete clearable :items="status" multiple chips dense label="Stato Avanzamento" v-model="stato"
                            item-text="stato" item_value="value" hide-details outlined></v-autocomplete>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col align="center">
                        <v-autocomplete clearable dense label="Tecnico" :items="technicians" item-value="ad_user_id"
                            item-text="name" :disabled="setTechnician" v-model="ad_user_id" hide-details outlined></v-autocomplete>
                    </v-col>

                </v-row>
                <br/>
                <br/>
                <v-flex d-flex>
                    <v-spacer></v-spacer>
                    <v-btn class="mt-n8" color="vecos" @click="getOrders()">RICERCA</v-btn>
                </v-flex>
                <br/>
                <b>Legenda:</b>
                <v-chip color="red" style="margin: 0px 5px 0px 5px;">Attività da pianificare</v-chip>
                <v-chip color="yellow" style="margin: 0px 5px 0px 5px;">Attività pianificata</v-chip>
                <v-chip color="blue lighten-2" style="margin: 0px 5px 0px 5px;">Attività effettuata</v-chip>
                <v-chip color="green" style="margin: 0px 5px 0px 5px;">Attività fatturata</v-chip>
                <br/>
                <v-data-table class="mt-3" :items="orders" :headers="headersOdvs" dense :footer-props="{'items-per-page-options':[50,100,200,-1]}">
                    <template v-slot:[`item.dataPromessa`]="{ item }">
                        {{ dateToItalianFormat(item.dataPromessa) }}
                    </template>
                    <template v-slot:[`item.dataOrdine`]="{ item }">
                        {{ dateToItalianFormat(item.dataOrdine) }}
                    </template>

                    <template v-slot:[`item.status`]="{ item }">
                        <v-chip :color="setColor(item.status)">{{ setStatus(item.status) }}</v-chip>
                    </template>

                    <template v-slot:[`item.note`]="{ item }">
                        <v-flex d-flex align-center>
                            <v-text-field v-model="item.note" hide-details></v-text-field>
                            <v-btn class="mb-3" :icon=true variant="text" @click="setNote(item)">
                                <v-icon>mdi-content-save-outline </v-icon>
                            </v-btn>
                        </v-flex>
                    </template>

                    <template v-slot:[`item.focus`]="{ item }">
                        <v-responsive align="center">
                            <v-btn variant="text" @click="getRigheOrdine(item)" :icon=true>
                                <v-icon>
                                    mdi-chevron-right
                                </v-icon>
                            </v-btn>
                        </v-responsive>
                    </template>
                </v-data-table>

                <v-btn color="vecos" class="mb-4" @click="home()">TORNA AL CALENDARIO</v-btn>
            </v-tab-item>
            <!-- FOCUS SULLE RIGHE DELL'ORDINE DI SELECTED -->
            <v-tab-item>
                <h2 align="center">Ordine selezionato: {{ selectedOrder.documentNo }}</h2>
                <v-data-table item-key="line" v-model="selectedOl" show-select :items="orderlines" :headers="headersOl">
                    <template v-slot:[`item.scadenza`]="{ item }">
                        {{ dateToItalianFormat(item.scadenza) }}
                    </template>

                    <template v-slot:[`item.statusOl`]="{ item }">
                        <v-chip :color="setColor(item.statusOl)">{{ setStatusOl(item.statusOl) }}</v-chip>
                    </template>

                    <template v-slot:[`item.dateProgrammed`]="{ item }">
                        {{ dateToItalianFormat(item.dateProgrammed) }}
                    </template>

                    <template v-slot:[`item.complete`]="{ item }">
                        <v-btn icon @click="setOrderLineCompleted(item)" :disabled="!['N','S'].includes(item.statusOl)"><v-icon>mdi-check</v-icon></v-btn>
                    </template>
                </v-data-table>

                <v-flex d-flex>
                    <v-btn color="vecos" class="mb-4" @click="goBack()">Indietro</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn :disabled="checkSelectedProgrammate" color="yellow" style="margin: 0px 5px 0px 5px"
                        @click="programmaCommesse()">Crea Attività Programmate</v-btn>
                    <!--<v-btn :disabled="checkSelectedEffettuate" color="blue lighten-2" style="margin: 0px 5px 0px 5px"
                    @click="programmaCommesse()">Crea Attività Effettuate</v-btn>-->
                </v-flex>
                <Event ref="event"></Event>
                <br>
            </v-tab-item>

        </v-tabs>


        <Event ref="event"></Event>
        <confirm-dialog ref="confirm"></confirm-dialog>
    </v-container>
</template>

<script>
/* eslint-disable */
import MixingCommonComp from '../mixin/MixingCommonComp';
import DataPicker from '../components/DataPicker.vue';
import Event from '../components/Event.vue';
import ConfirmDialog from '../components/ConfirmDialog.vue';

export default {
    mixins: [MixingCommonComp],
    components: { DataPicker, Event, ConfirmDialog },
    data() {
        return {

            tab: 0,
            ricercaCliente: "",
            c_bpartner_id: null,
            dataOrdine: null,
            stato: ["N","S","C"],
            documentNo: null,
            orders: [],
            bpartners: [],
            ad_user_id: null,
            technicians: [],
            headersOdvs: [
                { value: "documentNo", text: "Ordine", width: "10%" },
                { value: "dataOrdine", text: "Data Ordine", width: "10%" },
                { value: "dataPromessa", text: "Data Promessa", width: "10%" },
                { value: "ragioneSociale", text: "Ragione sociale", width: "10%" },
                { value: "status", text: "Stato", width: "10%" },                
                { value: "tecnici", text: "Assegnato a", width: "10%" },
                { value: "note", text: "Note", width: "25%" },
                { value: "focus", text: "Linee", width: "5%" }
            ],
            orderlines: [],
            headersOl: [
                { value: "line", text: "Numero riga", width: "10%" },
                { value: "description", text: "Descrizione", width: "40%" },
                { value: "numerofabbrica", text: "Num Fabbrica", width: "10%" },
                { value: "matricolainail", text: "Matricola", width: "10%" },
                { value: "scadenza", text: "Scadenza", width: "10%" },
                { value: "statusOl", text: "Stato", width: "10%" },
                { value: "dateProgrammed", text: "Data programmazione", width: "10%" },
                { value: "complete", text: "Completa", width: "10%" }      
            ],
            selectedOrder: {},
            selectedOl: [],
            status: [{ value: 'N', stato: "Da pianificare" },
            { value: 'S', stato: "Pianificato" },
            { value: 'C', stato: "Effettutato" },
            { value: 'I', stato: "Fatturato" }
            ],
            odvs: [],
            capiCommessa: [],

            createEvent: null,
            createStart: new Date().toISOString().substring(0, 10),
        }
    },
    methods: {
        home() {
            this.$router.push({ path: "/" });
        },

        goBack() {
            if (this.selectedOrder.length != 0) {
                this.selectedOrder = {};
            }
            this.tab = 0;
            this.selectedOl = [];
        },

        async getRigheOrdine(item) {
            this.tab = 1;
            this.selectedOrder = item;
            await this.getOrderlinesFromOrder(this.selectedOrder);
        },

        async getOrders() {
            this.showLoadingDialog(true, "Caricamento in corso...");
            let parameters = {};
            parameters.data = {
                c_bpartner_id: this.c_bpartner_id, dateOrdered: this.dataOrdine,
                documentno: this.documentNo, ad_user_id: this.ad_user_id,
                status: this.stato.join(',')
            };
            //MANCA STATUS 
            parameters.idempiereRestPath = "vecos/getOrders";
            await this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
                this.orders = response.data.orders;
                for (let i in response.data.orders) {
                    this.odvs.push(response.data.orders[i].documentNo)
                }
                this.showLoadingDialog(false);
                console.log(this.orders);
            })
        },

        async getOrderlinesFromOrder(item) {
            this.showLoadingDialog(true, "Caricamento...")
            let parameters = {};
            parameters.data = { c_order_id: item.c_order_id };
            parameters.idempiereRestPath = "vecos/getOrderlinesFromOrder";
            await this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
                this.orderlines = response.data.orderlines;
                console.log(this.orderlines);
                this.showLoadingDialog(false)
            })
        },

        async getTechnicians() {
            let parameters = {};
            parameters.data = {};
            parameters.idempiereRestPath = "vecos/getTechnicians";
            await this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
                this.technicians = response.data.technicians;
                for (let i in this.technicians) {
                    this.capiCommessa.push(this.technicians[i]);
                }
                this.setTechnician;
                console.log(this.technicians);
            })
        },

        programmaCommesse() {
            //Aggiungo a projects tutti gli fct_project_id 
            //delle righe dell'ordineselezionate
            let projects = this.selectedOl.map((x) => ({
                fct_project_id: x.fct_project_id,
            }))

            this.createEvent = {
                name: `New Event`,
                start: this.createStart,
                end: this.createStart,
                timed: true,
                username: "",

                ad_user_id: this.$session.get("ad_user_id"),
                users: this.technicians,
                selectedUsers: [this.technicians.filter((x) => x.ad_user_id == this.$session.get("ad_user_id"))],

                projects: [],
                selectedProjects: projects,
                a_asset_id: 0,
                c_bpartner_id: this.selectedOrder.c_bpartner_id,
                bpname: this.selectedOrder.ragioneSociale,
                bpvalue: this.selectedOrder.bpvalue,
            };
            this.$refs.event.open(this.createEvent);
        },

        setStatus(item) {
            if (item == 'N')
                return 'Da pianificare';
            else if (item == 'S' || item == 'P') {
                return 'Pianificata'
            }
            else if (item == 'C') {
                return 'Effettuata'
            }
            else if (item == 'I') {
                return 'Fatturata';
            }
        },
        setStatusOl(item) {
            if (item == 'N')
                return 'Da pianificare';
            else if (item == 'S') {
                return 'In corso'
            }
            else if (item == 'C') {
                return 'Da fatturare';
            }
            else if (item == 'I') {
                return 'Completato';
            }
        },
        setColor(item) {
            if (item == 'N') {
                return "red"
            }
            if (item == 'S' || item == 'P') {
                return 'yellow'
            }
            if (item == 'I') {
                return 'green'
            }
            if (item == 'C') {
                return 'blue lighten-2'
            }
        },

        setNote(item) {
            let parameters = {};
            parameters.data = { note: item.note, c_order_id: item.c_order_id };
            parameters.idempiereRestPath = "vecos/setNote";
            this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
                console.log(response);
                if (response.data.result == "OK") {
                    this.showInfoDialog("Nuova nota inserita correttamente")
                }
                else {
                    this.showErrorDialog("Impossibile aggiungere la nuova nota")
                }
            })
        },

        async setOrderLineCompleted(item){
            if(!await this.$refs.confirm.open("Completa" ,"Vuoi completare questa riga?")){
                return;
            }

            let parameters = {};
            parameters.data = { c_orderline_id: item.c_orderline_id };
            parameters.idempiereRestPath = "vecos/setOrderLineCompleted";
            this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
                if (response.data.result == "OK") {
                    item.statusOl = "C";
                }
                else {
                    this.showErrorDialog(response.data.message);
                }
            })
    }
    },

    async mounted() {
        this.showLoadingDialog(true, "Caricamento...");
        await this.getTechnicians();
        //await this.getOrders();
        this.showLoadingDialog(false);
    },

    computed: {
        checkSelectedProgrammate() {
            if (this.selectedOl.length == 0) {
                return true;
            }
            for (let i in this.selectedOl) {
                if (this.selectedOl[i].statusOl == 'C' || this.selectedOl[i].statusOl == 'I') {
                    return true;
                }
            }
            return false;
        },
        checkSelectedEffettuate() {
            if (this.selectedOl.length == 0) {
                return true;
            }
            for (let i in this.selectedOl) {
                if (this.selectedOl[i].statusOl != 'S') {
                    return true;
                }
            }
            return false;
        },
        setTechnician() {
            if (this.technicians.length == 1) {
                this.ad_user_id = this.technicians[0].ad_user_id;
                return true;
            }
            return false;
        },

    },
    watch: {
        ricercaCliente: function () {
            if (
                this.ricercaCliente == null ||
                this.ricercaCliente == "" ||
                this.ricercaCliente.length < 3
            )
                return;
            let parameters = {};
            parameters.data = { name: this.ricercaCliente };
            parameters.idempiereRestPath = "effecitech/cercaCliente";
            this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
                if (response.data.result == "OK") {
                    this.bpartners = response.data.listaClienti;
                    console.log(this.bpartners);
                }
            });
        }
    }
}
</script>