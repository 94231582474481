<template>
    <v-container>
        <h1>GOOGLE MAPS</h1>

        <v-row>
            <v-col>
                <DataPicker clearable label="Data inizio ricerca" v-model="data1"></DataPicker>
            </v-col>
            <v-col>
                <DataPicker clearable label="Data fine ricerca" v-model="data2"></DataPicker>
            </v-col>
        </v-row>

        <v-autocomplete :disabled="markers.length == 0" clearable :items="technicians" v-model="ad_user_id"
            label="Tecnico" item-value="ad_user_id" item-text="name" @change="setColorByTechnician"></v-autocomplete>
        <v-responsive align="center">
            <v-btn class="mb-4" color="vecos" @click="getScheduledJobs()">RICERCA</v-btn>
        </v-responsive>

        <div>
            <div id="map" style="height: 93vh;"></div>
            <table id="addressTable" style="display: none;"></table>
        </div>
        <v-dialog v-model="dialog" v-if="dialog">
            <v-card>
                <v-card-title>
                    LOCALITA' SELEZIONATA: {{ selectedLocation.address1 }} {{ selectedLocation.orderlines[0].bpname }}
                </v-card-title>
                <div v-if="ad_org_id == 1000016">
                    <v-expansion-panels>
                        <v-expansion-panel v-for="i in locationOrders" :key="i.c_order_id">
                            <v-expansion-panel-header>
                                <b>{{ i.documentno }}</b>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-responsive align="left">
                                    <v-btn color="vecos" class="mb-3" @click="downloadOrder(i.c_order_id)">
                                        <v-icon>mdi-download</v-icon>
                                        Scarica pdf ordine
                                    </v-btn>
                                </v-responsive>
                                <v-data-table :item-class="itemRowBackground" :headers="headers" :items="i.orderlines">

                                    <template v-slot:[`item.dataScadenziario`]="{ item }">
                                        <v-chip v-if="item.dataScadenziario != null">{{
                    dateToItalianFormat(item.dataScadenziario) }}</v-chip>
                                    </template>

                                    <template v-slot:[`item.scheduledJobUsers.name`]="{ item }">
                                        <v-chip v-for="i in item.scheduledJobUsers" :key="i.ad_user_id"> {{ i.name
                                            }}</v-chip>
                                    </template>

                                    <template v-slot:[`item.scheduledJobUsers.dateStart`]="{ item }">
                                        <v-chip v-for="i in item.scheduledJobUsers" :key="i.ad_user_id">
                                            {{ dateToItalianFormat(i.dateStart) }}</v-chip>
                                    </template>

                                    <template v-slot:[`item.statusOrderline`]="{ item }">
                                        {{ getOrderlinesColorStatus(item.statusOrderline) }}
                                    </template>

                                </v-data-table>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </div>
                <div v-if="ad_org_id == 1000023">
                </div>
                <br>
                <b v-if="nearLocations.length != 0" class="text-center ml-4">Nelle vicinanze:</b>
                <v-card color="vecos">
                    <v-expansion-panels>
                        <v-expansion-panel v-for="c in nearLocations" :key="c.location.c_location_id">
                            <v-expansion-panel-header>
                                {{ c.location.orderlines[0].bpname }} ( {{ c.location.address1 }} ): {{
                    c.distance.toFixed(2) }}km
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <b>{{ getOrdersDocumentNo(c.location) }}</b>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-card>

                <!-- <v-list>
                    <v-list-item v-for="c in nearLocations" :key="c.location.c_location_id">
                        {{ c.location.orderlines[0].bpname }}  <b>{{ getOrdersDocumentNo(c.location) }}</b>  ( {{ c.location.address1 }} ) : {{
                    c.distance.toFixed(2) }}km
                    </v-list-item>
                </v-list> -->

            </v-card>
            <v-btn color="vecos" @click="closeDialog()">CHIUDI FINESTRA</v-btn>
        </v-dialog>
    </v-container>
</template>

<script>
import MixingCommonComp from '../mixin/MixingCommonComp';
import DataPicker from '../components/DataPicker.vue';

export default {
    mixins: [MixingCommonComp],
    components: { DataPicker },
    data() {
        return {
            dialog: false,
            map: null,
            markers: [],
            infowindow: null,

            ad_user_id: null,
            technicians: [],

            scheduledJobs: [],
            data1: new Date('2024-06-06').toISOString().substring(0, 10),
            data2: new Date('2024-06-26').toISOString().substring(0, 10),
            orderedByLocations: {},

            sortedScheduledJobs: [],
            google: null,
            address: null,

            selectedLocation: null,
            nearLocations: null,

            downloadLink: null,

            key: null,
            mapID: null,

            jobs: [],
            ad_org_id: null,

            locationOrders: [],
            headers: [
                { text: "Line", value: "line" },
                { text: "N° matricola", value: "scadenziarioVerifiche.matricolainail" },
                { text: "N° fabbrica", value: "scadenziarioVerifiche.nfab" },
                { text: "Data programmata", value: "scheduledJobUsers.dateStart" },
                { text: "Stato riga dell'ordine", value: "statusOrderline" },
                { text: "Tecnici", value: "scheduledJobUsers.name" },
                { text: "Tipo", value: "description" },
                { text: "Data scadenza", value: "dataScadenziario" },
            ]
        };
    },
    methods: {
        loadScript(src) {
            return new Promise((resolve, reject) => {
                const script = document.createElement('script');
                script.src = src;
                script.async = true;
                script.defer = true;
                script.onload = () => {
                    this.google = window.google;
                    resolve();
                };
                script.onerror = reject;
                document.head.appendChild(script);
            });
        },

        async getUnfilteredTechnicians() {
            let parameters = {};
            parameters.data = {};
            parameters.idempiereRestPath = "vecos/getUnfilteredTechnicians";
            await this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
                this.technicians = response.data.unfilteredTechnicians;
                /*for (let i in this.technicians) {
                    this.capiCommessa.push(this.technicians[i]);
                }*/
                console.log(this.technicians);
            })
        },

        //PARTE SCHEDULED JOBS
        async getScheduledJobs() {
            this.jobs = []
            this.showLoadingDialog(true, "Caricamento in corso...")
            for (let i = 0; i < this.markers.length; i++) {
                this.markers[i].marker.setMap(null);
            }
            this.markers = [];

            this.sortedScheduledJobs = null;

            let parameters = {};
            parameters.data = { dateStart: this.data1, dateStop: this.data2 };
            parameters.idempiereRestPath = "vecos/getScheduledJobsSeritec";
            let response = await this.$store.dispatch("callIdempiereRest", parameters)

            this.scheduledJobs = response.data;

            for (let i in this.scheduledJobs.scheduledJobs) {
                this.jobs.push(this.scheduledJobs.scheduledJobs[i])
            }
            for (let a in this.scheduledJobs.notScheduledJobs) {
                this.jobs.push(this.scheduledJobs.notScheduledJobs[a])
            }
            //console.log(this.jobs);
            //console.log(this.scheduledJobs)


            const jobsLocations = this.jobs.map(obj => obj.location.c_location_id);
            const locationUniche = [...new Set(jobsLocations)];

            //console.log(locationUniche);

            let locations = [];

            for (let i in locationUniche) {
                let location = { ...this.jobs.find((x) => x.location.c_location_id == locationUniche[i]).location };
                locations.push(location);

                let scheduledJobsLocations = (this.jobs.filter((x) => x.location.c_location_id == locationUniche[i]));

                const jobsOrders = scheduledJobsLocations.map(obj => obj.c_orderline_id);
                const ordersUnici = [...new Set(jobsOrders)];

                let orderlines = [];
                for (let a in ordersUnici) {
                    let order = { ...scheduledJobsLocations.find((x) => x.c_orderline_id == ordersUnici[a]) }

                    delete order.location;
                    delete order.fc_scheduledjob_id;
                    delete order.fct_scheduledjobprj_id;
                    delete order.scheduledJobUsers;
                    orderlines.push(order)

                    order.scheduledJobs = this.jobs.filter((x) => x.c_orderline_id == ordersUnici[a]);
                }
                location.orderlines = orderlines;

                await this.getLatitudeLongitude(location);
            }
            this.sortedScheduledJobs = locations;
            console.log(this.sortedScheduledJobs);
            this.showLoadingDialog(false);
        },

        async getLatitudeLongitude(location) {
            let latitude = null;
            let longitude = null;

            let parameters = {};
            parameters.data = { c_location_id: location.c_location_id }
            parameters.idempiereRestPath = "effecitech/getLatitudeLongitude";
            let response = await this.$store.dispatch("callIdempiereRest", parameters)
            //console.log(response);

            latitude = response.data.latitude;
            longitude = response.data.longitude;

            if (latitude == null && longitude == null) {
                //Chiamata a google per indirizzo azienda + creazione marker
                this.address = this.getAddress(location);
                //console.log(this.address);

                const latLong = await this.geocodeAddress(this.address, location);

                latitude = latLong.lat;
                longitude = latLong.lng;
            }
            //per oggetti con lat long già presenti  
            //creo marker
            const myLatLng = { lat: latitude, lng: longitude };

            const color = this.getMarkerColor(location);
            const pin = new this.google.maps.marker.PinElement({
                background: color,
                borderColor: "black",
                glyphColor: "white",
            });

            let marker = new this.google.maps.marker.AdvancedMarkerElement({
                position: myLatLng,
                map: this.map,
                title: this.getAddress(location),
                content: pin.element,
            })

            this.markers.push({ marker: marker, location: location })
            //console.log(this.markers);
            marker.addListener("click", async () => {

                //Prendo gli ordini unici dentro al marker 
                const cOrders = location.orderlines.map(obj => obj.c_order_id);
                const cOrdersUnici = [...new Set(cOrders)];
                console.log(cOrdersUnici);

                //chiamo il metodo per il recupero degli ordini 
                await this.getOrdersFromLocations(location.c_location_id);

                this.locationOrders = this.locationOrders.filter((x) => x.c_order_id ==
                    cOrdersUnici.find((a) => a == x.c_order_id));


                this.selectedLocation = location;
                console.log(this.selectedLocation);

                this.nearLocations = [];
                for (let i in this.markers) {
                    if (marker !== this.markers[i].marker) {
                        const distance = this.haversineDistance(
                            marker.position.lat,
                            marker.position.lng,
                            this.markers[i].marker.position.lat,
                            this.markers[i].marker.position.lng,
                        );
                        //console.log(distance);
                        if (distance < 20) { // Se la distanza è inferiore a 20 km
                            this.nearLocations.push({ marker: this.markers[i].marker, location: this.markers[i].location, distance: distance });
                            //console.log(this.markers[i].content);
                        }
                    }
                }
                this.dialog = true;
            })
        },
        // FINE PARTE SCHEDULED JOBS

        async initMap() {
            //const google = window.google;


            await this.loadScript('https://maps.googleapis.com/maps/api/js?key=' + this.key + '&libraries=places,marker&v=weekly');
            const { Map } = await this.google.maps.importLibrary("maps");
            //const { AdvancedMarkerElement/*, PinElement*/ } = await google.maps.importLibrary("marker");
            //const geocoder = new google.maps.Geocoder();
            //this.infowindow = new google.maps.InfoWindow();

            // La mappa, centrata su una posizione iniziale
            this.map = new Map(document.getElementById("map"), {
                minZoom: 4,
                maxZoom: 15,
                zoom: 8,
                center: { lat: 45.6844, lng: 11.5674 },
                mapId: this.mapID, // Usa l'ID mappa creato nel Google Cloud Console
            });

            // Funzione per calcolare la distanza Haversine
            this.haversineDistance = (lat1, lng1, lat2, lng2) => {
                const R = 6371; // Radius of the Earth in km
                const dLat = (lat2 - lat1) * Math.PI / 180;
                const dLng = (lng2 - lng1) * Math.PI / 180;
                const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                    Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
                    Math.sin(dLng / 2) * Math.sin(dLng / 2);
                const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
                const distance = R * c;
                return distance;
            };
        },

        getAddress(location) {
            let address = "";
            if (location.address1 != null) {
                address = address + location.address1 + " ";
            }
            if (location.address2 != null) {
                address = address + location.address2 + " ";
            }
            if (location.address3 != null) {
                address = address + location.address3 + " ";
            }
            if (location.address4 != null) {
                address = address + location.address4 + " ";
            }
            if (location.address5 != null) {
                address = address + location.address5 + " ";
            }
            if (location.city != null) {
                address = address + location.city + " ";
            }
            if (location.locodeCity != null) {
                address = address + location.locodeCity + " ";
            }
            if (location.locodeLocation != null && location.locodeCity == null) {
                address = address + location.locodeLocation + " ";
            }
            if (location.postal != null) {
                address = address + location.postal;
            }
            return address;
        },

        // Funzione per ottenere le coordinate
        geocodeAddress(address, location) {
            const geocoder = new this.google.maps.Geocoder();
            return new Promise((resolve, reject) => {
                geocoder.geocode({ address: address }, (results, status) => {
                    if (status === 'OK') {
                        const position = results[0].geometry.location;
                        console.log(position);

                        let latitude = results[0].geometry.location.lat();
                        let longitude = results[0].geometry.location.lng();

                        this.setLatitudeLongitude(latitude, longitude, location);

                        resolve({ lat: latitude, lng: longitude });
                    } else {
                        reject('Geocode was not successful for the following reason: ' + status);
                    }
                });
            });
        },

        setLatitudeLongitude(latitude, longitude, location) {
            let parameters = {};
            parameters.data = { latitude: latitude, longitude: longitude, c_location_id: location.c_location_id };
            parameters.idempiereRestPath = "effecitech/setLatitudeLongitude";
            this.$store.dispatch("callIdempiereRest", parameters).then(() => {
            });

        },

        closeDialog() {
            this.selectedScheduledJob = null;
            this.dialog = false;
            this.locationOrders = [];
        },

        getMarkerColor(location) {
            
            //console.log(location)
            let alreadyProgrammed = 0;
            let partiallyProgrammed = 0;
            let done = 0;
            let color = "";
            
            let orderlines = location.orderlines;
            let found = false;

            
            for (let i in orderlines) {
                let scheduledJobs = orderlines[i].scheduledJobs
                for (let a in scheduledJobs) {
                    if (scheduledJobs[a].scheduledJobUsers) {
                        let scheduledJobUsers = scheduledJobs[a].scheduledJobUsers;
                        found = scheduledJobUsers.some((x) => x.ad_user_id === this.ad_user_id)
                        if (found) {
                            break;
                        }
                    }
                }
                if (orderlines[i].orderStatus == "S") {
                    alreadyProgrammed = alreadyProgrammed + 1;
                }
                else if (orderlines[i].orderStatus == "P"){
                    partiallyProgrammed = partiallyProgrammed + 1
                }
                else if (orderlines[i].orderStatus == "C" || orderlines[i].orderStatus == "I") {
                    done = done + 1;
                }
            }
            if (!found) {
                color = "grey"
            }
            
            if (alreadyProgrammed == location.orderlines.length) {
                color = "green";
            }
            else if (done == location.orderlines.length) {
                color = "blue";
            }
            else if (alreadyProgrammed == 0) {
                color = "red";
            }
            else if(partiallyProgrammed > 0 && (alreadyProgrammed != location.orderlines.length)||(done != location.orderlines.length)){
                color = "yellow";
            }
            
            return color;
        },

        setColorByTechnician() {
            if (this.ad_user_id != null) {
                for (let i in this.markers) {
                    const color = this.getMarkerColor(this.markers[i].location);
                    const pin = new this.google.maps.marker.PinElement({
                        background: color,
                        borderColor: "black",
                        glyphColor: "white",
                    });
                    this.markers[i].marker.content = pin.element;
                }
            }
        },
        async setTechnician() {
            for (let i in this.technicians) {
                if (this.technicians[i].ad_user_id == this.$session.get("ad_user_id") && this.technicians[i].isManual == true) {
                    this.ad_user_id = this.technicians[i].ad_user_id
                }
            }
        },

        downloadOrder(c_order_id) {
            this.showLoadingDialog(true, "Download in corso...");
            let parameters = {};
            parameters.data = { c_order_id: c_order_id };
            parameters.idempiereRestPath = "seritec/downloadOrder";
            this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
                if (response.data.result == "OK") {
                    this.showLoadingDialog(false);
                    this.downloadLink = response.data;
                    console.log(this.downloadLink)
                    this.downloadFile();
                } else {
                    this.showErrorDialog(response.data.message);
                }
            });
        },
        downloadFile() {
            const linkSource = "data:application/pdf;base64," + this.downloadLink.data;
            const downloadLink = document.createElement("a");
            const fileName = this.downloadLink.documentno + ".pdf";

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        },

        getGoogleMapsIDs() {
            let parameters = {};
            parameters.data = {};
            parameters.idempiereRestPath = "vecos/getGoogleMapsIDs";
            this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
                this.key = response.data.googleMapsKeyAPI;
                this.mapID = response.data.googleMapID;
            });
        },

        async getOrdersFromLocations(c_location_id) {
            this.showLoadingDialog(true, "Caricamento...")
            let parameters = {};
            parameters.data = { c_location_id: c_location_id }
            parameters.idempiereRestPath = "vecos/getOrdersFromLocation";
            await this.$store.dispatch("callIdempiereRest", parameters).then((response) => {

                this.locationOrders = response.data.orders;
                console.log(this.locationOrders)
                this.showLoadingDialog(false);
            });
        },

        getOrderlinesColorStatus(item) {
            if (item == 'N')
                return 'Da pianificare';
            else if (item == 'S') {
                return 'In corso'
            }
            else if (item == 'C') {
                return 'Da fatturare';
            }
            else if (item == 'I') {
                return 'Completato';
            }
        },

        getOrdersDocumentNo(location) {
            const documentNo = location.orderlines.map(obj => obj.documentNo);
            const documentNoUnici = [...new Set(documentNo)];
            //console.log(documentNoUnici)
            let documentNos = "";
            for (let i in documentNoUnici) {
                documentNos = documentNos + ' \n ' + documentNoUnici[i]
            }
            return documentNos

        },

        itemRowBackground: function (item) {
            if (item.statusOrderline == 'I') {
                return 'style-1';
            }
        },

        async checkADOrgID() {
            this.ad_org_id = this.$session.get("ad_org_id");
        },
    },
    async mounted() {
        await this.checkADOrgID();
        await this.getGoogleMapsIDs();
        await this.getUnfilteredTechnicians();
        await this.initMap();
        await this.setTechnician();
    }
}
</script>

<style>
.style-1 {
    background-color: rgb(165, 165, 153)
}
</style>