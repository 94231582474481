/* eslint-disable */
export class Conf {
  //static idempiereUrl = "http://192.168.60.101:8095/";
  static idempiereUrl = "https://idtest.seritec.eu/";

  static windowTitle = "Smart Calendar";

  static buildNo = "2024-07-18_085639"

}
